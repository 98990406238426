import React from 'react'

import Layout from '../layouts/layout'

const OchranaOsobnichUdaju = () => (
  <Layout>
    {() => (
      <>
        <h1 className="tc">Ochrana osobních údajů</h1>
        <h2 className="tc">
          <a href="/zpracovani-osobnich-udaju">Zpracování osobních údajů</a>
        </h2>
        <h2 className="tc">
          <a href="/cookies">Cookies</a>
        </h2>
        <h2 className="tc">
          <a href="/kategorie-a-rozsah">Kategorie a rozsah</a>
        </h2>
        <h2 className="tc">
          <a href="/subjekty-s-pristupem">Subjekty s přístupem</a>
        </h2>
        <h2 className="tc">
          <a href="/prava-subjektu">Práva subjektu</a>
        </h2>
      </>
    )}
  </Layout>
)

export default OchranaOsobnichUdaju
